body {
  font-family: "Nunito Sans", sans-serif;
  overflow-x: hidden;
}
.item {
  text-decoration: none;
  display: block;
  position: relative;
  padding: 0.2em 0;
}
.item::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.1em;
  background-color: hotpink;
  opacity: 0;
  transition: opacity 300ms, transform 500ms;
  transform: scale(0);
  transform-origin: center;
}
.item:hover::after,
.item:focus::after {
  opacity: 1;
  transform: translate3d(0, 0.2em, 0);
  transform: scale(1);
}

.rhap_container {
  border: none !important; /* Important to override any existing styles */
}

.rhap_progress-bar-show-download {
  border: none !important;
}
.shop {
  display: inline-block;
  color: #111111;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 4px;
  text-transform: uppercase;
  padding: 3px 0;
  position: relative;
}
/* .calcBg {
  background: linear-gradient(to left, rgb(0, 0, 0), rgb(255, 255, 255));
} */
.centered-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.css-m26sq7 {
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  padding: 2rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 6px 12px;
  background: var(--chakra-colors-white);
  color: var(--chakra-colors-black);
  position: relative;
}
/* 
.imageStyle {
  max-width: max-content;
} */
